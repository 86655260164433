import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {Helmet} from "react-helmet";
import '@css/style.css'
import * as styleCSS from './index.module.css'
import Favicon from '@img/favicon.ico'

import FontUrl1 from '@fontsource/mate-sc/files/mate-sc-latin-400-normal.woff2'
import FontUrl2 from '../../../static/fonts/AnythingScript1-0W8Er.ttf'

import SEO from '../seo';

import IMAGE_URL from '../../../static/images/seo-img.jpg';
const META_DESCRIPTION = `Minggu, 20 Maret 2022`;


const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
	  query {
			site {
				siteMetadata {
					title
					description
					author
					keywords
					image
				}
			}
	  }
	`)

  return (
    <div>
			<Helmet>
					<meta charSet="utf-8" />
					{/* Favicon */}
					<link rel="icon" type="image/png" href={Favicon} />
					{/* Preload fonts */}
					<link rel="preload"
								as="font"
		          	href={FontUrl1}
								type="font/woff2"
								crossOrigin="anonymous" />
					<link rel="preload"
								as="font"
								href={FontUrl2}
								type="font/woff2"
								crossOrigin="anonymous" />
			</Helmet>
			{/* font and SEO tags */}
			<SEO title="The Wedding of Triana & Singgih" description={META_DESCRIPTION} image={IMAGE_URL} />
      <main>
				<div tabIndex="-1">
					<div className={styleCSS.css1rvuyv0}>
						<div className={styleCSS.cssfudml1}>
							{children}
						</div>
					</div>
				</div>
      </main>
    </div>
  )
}

export default Layout