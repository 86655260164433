import React, { Fragment, useState } from 'react';
import * as styleCss from './index.module.css'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
	useDisclosure,
	Text,
	Image,
	Divider
} from '@chakra-ui/react'
import { MdMusicNote, MdMusicOff, MdMenu, MdMenuOpen } from "react-icons/md";
import LogoTS from '@img/logo.png';
import scrollTo from 'gatsby-plugin-smoothscroll';

function FloatingButton({ isInvitation }) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [play, setPlay] = useState(true);

  const toggleMusic = () => {
    const myAudio = document.getElementById('myAudio');
    /**
     * This function built-in with html5 function
     * doc: https://www.w3schools.com/tags/ref_av_dom.asp
     */
    if (play) {
      myAudio.pause();
    } else {
      myAudio.play();
    }

    setPlay(!play);
  };

	const handleCloseNav = async (nav, e) => {
    e.preventDefault(); //prevent transition
    await onClose(); // wait until it finishes

    // redirect 1 second after onClose() finishes.
    window.setTimeout(() => {
			scrollTo(nav)
    }, 300)
	};

	return(
		<Fragment>
			<div>
				<div>
						<button onClick={toggleMusic} type="button" className={styleCss.css1b1hkxe}>
							{play ? <MdMusicNote /> : <MdMusicOff/>}
						</button>
				</div>
				<button type="button" className={styleCss.cssohjk6m} onClick={ isOpen ? onClose : onOpen } >
					{isOpen ? <MdMenuOpen /> : <MdMenu/>}
				</button>

				<Drawer
					isOpen={isOpen}
					placement='right'
					onClose={onClose}
					blockScrollOnMount={false}
					className={styleCss.css1lz89iw}
				>
					<DrawerOverlay />
					<DrawerContent className={styleCss.cssio0w5n}>
						<DrawerBody className={styleCss.cssc0a0q2}>
							<Image src={LogoTS} className={styleCss.cssfkvnwr} alt='Logo TS'></Image>
							<div className={styleCss.cssvhsw7n}>
									<div>
										<Text className={styleCss.cssxg9ntm} onClick={(e) => handleCloseNav('#couple', e) }>Bride &amp; Groom</Text>
										<Divider orientation='horizontal' className={styleCss.cssdwbyx} />
									</div>
									<div>
										<Text className={styleCss.cssxg9ntm} onClick={(e) => handleCloseNav('#schedule', e)}>Wedding Details</Text>
										<Divider orientation='horizontal' className={styleCss.cssdwbyx} />
									</div>
									{ isInvitation ?
									<div>
										<div>
											<Text className={styleCss.cssxg9ntm} onClick={(e) => handleCloseNav('#covid', e) }>Health Protocol</Text>
											<Divider orientation='horizontal' className={styleCss.cssdwbyx} />
										</div>
										<div>
												<Text className={styleCss.cssxg9ntm} onClick={(e) => handleCloseNav('#rsvp', e) }>RSVP</Text>
											<Divider orientation='horizontal' className={styleCss.cssdwbyx} />
										</div>
									</div>
										: '' }
									<div>
											<Text className={styleCss.cssxg9ntm} onClick={(e) => handleCloseNav('#gallery', e) }>Gallery</Text>
										<Divider orientation='horizontal' className={styleCss.cssdwbyx} />
									</div>
									<div>
										<Text className={styleCss.cssxg9ntm} onClick={(e) => handleCloseNav('#wishes', e) }>Wishes</Text>
										<Divider orientation='horizontal' className={styleCss.cssdwbyx} />
									</div>
							</div>
						</DrawerBody>

						<DrawerFooter className={styleCss.cssk0waxj}>
							<div className={styleCss.css87ralv}>
									<Text className={styleCss.cssa0m57b}>Created with Love by singgih.dev</Text>
									<Text className={styleCss.cssa0m57b}>2022 Triana &amp; Singgih. All Rights Reserved</Text>
							</div>
						</DrawerFooter>
					</DrawerContent>
				</Drawer>
			</div>
		</Fragment>
	)
}

export default FloatingButton