import React, { Fragment } from 'react'
import * as styleCss from './index.module.css'
import logoTS from '@img/logo.png'
import Slide from '@stahl.luke/react-reveal/Slide';
import { Heading } from '@chakra-ui/react'
import {useI18next} from 'gatsby-plugin-react-i18next';
import { StaticImage } from "gatsby-plugin-image"

function ScheduleSection({ isInvitation }) {
	const {t} = useI18next();
	return (
		<Fragment>
			<section className={styleCss.css1qq679y} id='schedule'>
				<div className={styleCss.css1umt2ei}>
						<Slide bottom>
								<div className={styleCss.cssgmuwbf}>
										<img className={styleCss.css1gn5ro3} src={logoTS} alt='Logo TS'/>
								</div>
						</Slide>
						<Slide bottom>
								<p className={styleCss.css1dhto7q}>
										“When we fall in love, <br />
										it will be forever”
								</p>
						</Slide>
						<div className={styleCss.csscxo7is}>
								<div className={styleCss.css1izlew2}>
										<Slide bottom>
												<div>
														<div className={styleCss.css1ubu69r}>
															<Heading as='h2' fontWeight='light' fontSize='4xl' textAlign='center' color='mainColorText'>{t('Wedding Vows')}</Heading>
																<p className={styleCss.css1txez04}>
																		{t('Sunday')} <br />
																		20 {t('March')} 2022 <br/>
																		{isInvitation ? '09.00 WIB' : ''}
																</p>
														</div>
												</div>
										</Slide>
										{isInvitation ?
											<Slide bottom>
													<div className={styleCss.css1ubu69r}>
														<Heading as='h2' fontWeight='light' fontSize='4xl' textAlign='center' color='mainColorText'>{t('Wedding Reception')}</Heading>
															<p className={styleCss.css1txez04}>
																	{t('Sunday')} <br />
																	20 {t('March')} 2022<br />
																	11.00 - 14.00 WIB
															</p>
													</div>
											</Slide>
											: ''
										}
										<Slide bottom>
											<p className={styleCss.css7wi926}>
													Madison Avenue
											</p>
											<p className={styleCss.cssvtnzrv}>Jl Lingkar selatan km 7,7, Taman Baru, Citangkil, {t('Cilegon')}, Banten 42442</p>
											{isInvitation ?
												<div className={styleCss.css1kj70zf}>
														<div className={styleCss.cssa9v878}>
																<button type="button" className={styleCss.css1bkzs9c} onClick={() => {window.open('https://goo.gl/maps/yZyL3uCQyXv9Expb8');}}>
																		<span className={styleCss.css1wh2kri}>
																				<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 288 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
																						<path
																								d="M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z"
																						></path>
																				</svg>
																		</span>
																		{t('See Location')}
																</button>
														</div>
												</div>
											: '' }
										</Slide>
								</div>
						</div>
				</div>
			</section>

			<StaticImage
				src='../../images/NIN_1973-03.jpg'
				alt="Triana Singgih"
				placeholder="blurred"
				layout="constrained"
			/>
		</Fragment>
	)
}

export default ScheduleSection