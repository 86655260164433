import React, { Fragment, useState } from 'react'
import {
  FormControl,
	Flex,
	Box,
	Spacer,
	Input,
	Textarea,
	Button,
	useToast
} from '@chakra-ui/react'
// firebase
// import { db } from '../../../firebase';
// import { collection, addDoc }  from "firebase/firestore";

const DELAY_TIME = 500;

function WishForm() {
  const [name, setName] = useState("")
  const [content, setContent] = useState("")
	const [loading, setLoading] = useState(false)
	const [alreadyDownloadData, setAlreadyDownloadData] = useState(false);

	const toast = useToast()

  const handleWishSubmission = async e => {
    e.preventDefault()

		setLoading(true);

    let wish = {
      name: name,
      content: content,
      pId: null,
      time: new Date(),
    }
    setName("")
    setContent("")

    if (!alreadyDownloadData) {
			// const docRef = await addDoc(collection(db, "wish"), wish)
			// console.log("Document written with ID: ", docRef.id);

      setTimeout(() => {
        setLoading(false);
        setAlreadyDownloadData(true);
				toast({
					title: 'Success',
					description: "Your wishes already submitted, Thank you!",
					status: 'success',
					duration: 5000,
					isClosable: true,
				})
      }, DELAY_TIME);

		}else {
			setLoading(false);
			setAlreadyDownloadData(true);
			toast({
				title: 'Warning!',
				description: "You have submitted wishes!",
				status: 'warning',
				duration: 9000,
				isClosable: true,
			})
		}

  }

	return(
		<Fragment>
			<form onSubmit={e => handleWishSubmission(e)}>
				<FormControl isRequired>
					<Input
						value={name}
						onChange={e => setName(e.target.value)}
						mt={2} placeholder='Your Name'  style={{backgroundColor: 'white'}}
					/>
					<Textarea
						onChange={e => setContent(e.target.value)}
						value={content}
						mt={2} placeholder='Your Wishes'  style={{backgroundColor: 'white'}}
					/>
					<Flex mt={2}>
						<Spacer />
						<Box>
							<Button
								isLoading={loading}
								type='submit' h={8} bgColor='#cf9c2f' color='white' fontSize='sm'
							>Send Wishes</Button>
						</Box>
					</Flex>
				</FormControl>
			</form>
		</Fragment>
	)
}

export default WishForm