import React, { Fragment, useState } from 'react'
import * as styleCss from './index.module.css'
import {
  FormControl,
	Input,
	Button,
	Stack,
	Select,
	FormLabel,
	useToast
} from '@chakra-ui/react'
import { RiMailSendFill } from "react-icons/ri";
import {useI18next} from 'gatsby-plugin-react-i18next';
// firebase
// import { db } from '../../../firebase';
// import { collection, addDoc, updateDoc, doc }  from "firebase/firestore";

const DELAY_TIME = 1000;

function AttendanceForm({ docData }) {
	const {t} = useI18next();

  const [name, setName] = useState(docData.name ? docData.name : '')
  const [phone, setPhone] = useState(docData.phone ? docData.phone : '')
  const [isAttend, setIsAttend] = useState(docData.isAttend ? docData.isAttend : true)
  const [withPartner, setWithPartner] = useState(docData.withPartner ? docData.withPartner : false)
	const [id, setId] = useState(docData.id ? docData.id : '')

  const [loading, setLoading] = useState(false)
  const [alreadyDownloadData, setAlreadyDownloadData] = useState(false);

  const toast = useToast()

	// console.log("docData :", docData)

  const handleAttendanceSubmission = async e => {
    e.preventDefault()

		setLoading(true);
		const stringToBoolean = (string) => string === 'false' ? false : !!string

    let attendances = {
      name: name,
			phone: phone,
			isAttend: stringToBoolean(isAttend),
			withPartner: stringToBoolean(isAttend) ? stringToBoolean(withPartner) : false,
      time: new Date(),
    }

    console.log(attendances)

    if (!alreadyDownloadData) {

			// if (id) {
			// 	const docRef = doc(db, "attendances", id)
			// 	await updateDoc(docRef, {
			// 		name: attendances.name,
			// 		phone: attendances.phone,
			// 		isAttend: attendances.isAttend,
			// 		withPartner: attendances.withPartner,
			// 		time: new Date()
			// 	})
			// } else{
			// 	const docRef = await addDoc(collection(db, "attendances"), attendances)
			// 	setId(docRef.id)
			// }

      setTimeout(() => {
        setLoading(false);
        setAlreadyDownloadData(true);
				toast({
					title: 'Success',
					description: "Your response already submitted, Thank you!",
					status: 'success',
					duration: 9000,
					isClosable: true,
				})
      }, DELAY_TIME);

    } else {

			if (id) {
				// const docRef = doc(db, "attendances", id)
				// await updateDoc(docRef, {
				// 	name: attendances.name,
				// 	phone: attendances.phone,
				// 	isAttend: attendances.isAttend,
				// 	withPartner: attendances.withPartner,
				// 	time: new Date()
				// })
				setLoading(false);
				toast({
					title: 'Success',
					description: "Your response already updated, Thank you!",
					status: 'success',
					duration: 5000,
					isClosable: true,
				})
			} else{
				setLoading(false);
				toast({
					title: 'Warning',
					description: "ID not found!",
					status: 'warning',
					duration: 5000,
					isClosable: true,
				})
			}

    }

  }

	return(
		<Fragment>
			<form onSubmit={e => handleAttendanceSubmission(e)}>
				<FormControl className={styleCss.css18gemd9} isRequired>
					<Stack my='8px'>
						<Input
							value={name}
							onChange={e => setName(e.target.value)}
							mt={2} placeholder={t('Name')}
							bgColor='#cf9c2f'
							color='white'
							size='sm'
							className={styleCss.css1d5ccm8}
						/>
						<Input
							value={phone}
							onChange={e => setPhone(e.target.value)}
							mt={2} placeholder={t('Phone')}
							bgColor='#cf9c2f'
							color='white'
							size='sm'
							className={styleCss.css1d5ccm8}
						/>

						<FormLabel htmlFor='isAttend' fontSize='sm' className={styleCss.css9cfhjf}>{t('Will you attend')}</FormLabel>
						<Select
							bgColor='#cf9c2f' id='isAttend' color='white' className={styleCss.cssd9ia2j} size='sm'
							onChange={e => setIsAttend(e.target.value)} value={isAttend}
							>
								<option value={true}>{t('Yes attend')}</option>
								<option value={false}>{t('No attend')}</option>
						</Select>

						<FormLabel htmlFor='withPartner' fontSize='sm' className={styleCss.css9cfhjf}>{t('Will you bring')}</FormLabel>
						<Select
							bgColor='#cf9c2f' id='withPartner' color='white' className={styleCss.cssd9ia2j} size='sm'
							onChange={e => setWithPartner(e.target.value)} value={withPartner} isDisabled={isAttend === 'false' ? !!isAttend : false}
							>
								<option value={true}>{t('Yes partner')}</option>
								<option value={false}>{t('No myself')}</option>
						</Select>

						<Button
							leftIcon={<RiMailSendFill/>}
							backgroundColor='white'
							className={styleCss.css1yni35y}
							size='sm'
							type='submit'
							isFullWidth={false}
							isLoading={loading}
							>
								{t('Submit Confirmation')}
						</Button>
					</Stack>
				</FormControl>
			</form>
		</Fragment>
	)
}

export default AttendanceForm