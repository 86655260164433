import React, { Fragment } from 'react';
import * as styleCss from './index.module.css'
import LogoTS from '@img/logo.png';
import bgHeader from '@img/bg-header.jpeg'
import bgHeader2 from '@img/bg-header2.jpg'
import bgHeader3 from '@img/bg-header3.jpg'
import bgHeader4 from '@img/bg-header4.jpg'
import Slide from '@stahl.luke/react-reveal/Slide';
import {useI18next} from 'gatsby-plugin-react-i18next';

function QuranSection() {
	const {t} = useI18next();
  return (
    <Fragment>
			<div id="start-scroll" >
				<div className={styleCss.css11dj2b2}>
						<div className={styleCss.slide}>
								<div style={{ backgroundImage: `url(${bgHeader})` }}></div>
								<div style={{ backgroundImage: `url(${bgHeader3})` }}></div>
								<div style={{ backgroundImage: `url(${bgHeader2})` }}></div>
								<div style={{ backgroundImage: `url(${bgHeader4})` }}></div>
						</div>
						<div className={styleCss.css1mbhdau}></div>
				</div>
				<div className={styleCss.css6yexcd}>
						<div className={styleCss.css1gutblt}>
								<div className={styleCss.cssgmuwbf}>
										<img
												className={styleCss.css1sg7bn7}
												src={LogoTS}
												alt='Logo TS'
										/>
								</div>
								<div>
								<Slide bottom>
									<p className={styleCss.css1ri0di5}>{t('Quran')}<br /></p>
								</Slide>
								</div>
								<div>
									<Slide bottom>
										<p className={styleCss.cssomh4o1}>(QS. Ar-Rum : 21)</p>
									</Slide>
								</div>
						</div>
				</div>
			</div>
    </Fragment>
  );
}

export default QuranSection;
