import React from 'react';
import { btnOpen } from './index.module.css';
import { bool, func } from 'prop-types';
import { Button } from '@chakra-ui/react'
import { HiMailOpen } from "react-icons/hi";
import {useI18next} from 'gatsby-plugin-react-i18next';

function ClickToSeeDetail({ loading, onClick }) {
	const {t} = useI18next();
  return (
    <div className={btnOpen} onClick={onClick}>
			<Button
				isLoading={loading}
				leftIcon={<HiMailOpen/>}
				size='sm'
				bgColor='#cf9c2f'
				borderRadius='16px'
				color='white'
				fontWeight='normal'
				fontSize='sm'
				background='blackAlpha.500'
				animation='pulse 1s ease infinite'
				_hover={{ bg: 'blackAlpha.500' }}
				_active={{
					bg: 'blackAlpha.500',
					transform: 'scale(0.98)',
					borderColor: 'blackAlpha.300',
				}}
				_focus={{
					boxShadow:
						'0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)'
					}}
				// className={css16zyllx}
			>
			{t('Open Invitation')}
			</Button>

    </div>
  );
}

ClickToSeeDetail.propTypes = {
  loading: bool.isRequired,
  onClick: func.isRequired,
};

export default ClickToSeeDetail;