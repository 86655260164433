export const wishes =
[
	{
		id: "0SlxQdsL3KrIYcYsUcNr",
		name: "Diah ayu",
		content: "Happy wedding trinaaaa🥰 semoga bahagia selalu ya trin bersama suamiiik💕",
		time: "1647780482",
		timeFormatted: "3/20/22 12:48"
	},
	{
		id: "6Dpw3Q8ymDU2DurVRpdl",
		name: "Ayu tanissa",
		content: "Trinnn barakallah, selamat atas pernikahannya, semoga sakinah mawaddah warahmah ya 💐",
		time: "1647756823",
		timeFormatted: "3/20/22 6:13"
	},
	{
		id: "7jy7xXcwtWiDhvxK59Ym",
		name: "SUTAR ",
		content: "Bârakallâhu, Selamat mas Singgih dan mbak Triana, semoga menjadi keluarga sakinah mawadah warohmah. Aamiin",
		time: "1647730951",
		timeFormatted: "3/19/22 23:02"
	},
	{
		id: "E4ah5jmVylgb6sK2Y0F9",
		name: "Melinda Alvionita",
		content: "Mba triiiiin!!! Selamat yaaaa, semoga lancar persiapan dan acaranya nanti, semoga selalu diberikan keberkahan, kebahagiaan, langgeng hingga tua nanti, dan dipertemukan kembali di jannah aamiin",
		time: "1647660348",
		timeFormatted: "3/19/22 3:25"
	},
	{
		id: "FiUik7E0nINpyBZWNcpV",
		name: "Nisa Prisma",
		content: "Semoga lancar sampe hari H dan bahagia dunia akhirat yaaa triiiin 🤍🤍🤍🤍",
		time: "1647621092",
		timeFormatted: "3/18/22 16:31"
	},
	{
		id: "GIde4RNcDMBYbKkoUUb4",
		name: "cila",
		content: "selamat menempuh hidup baru dan membangun keluarga baru kak trina & mas singgih. semoga menjadi keluarga yang sakinah mawaddah warrahmah, disatukan sampai akhir hayat. aamiin",
		time: "1647537851",
		timeFormatted: "3/17/22 17:24"
	},
	{
		id: "HhJYsNyYkpeUTpZ6HZnt",
		name: "Ika Arifieni",
		content: "Selamat ya kak triana, semoga di permudah dan di lancarkan semuanyaa. Aamiin 🤲🤲",
		time: "1647527476",
		timeFormatted: "3/17/22 14:31"
	},
	{
		id: "JHcg4dov3WPHG1J7SJP6",
		name: "MiNato NaMiKaZe",
		content: "Selamat mas Singgih dan mba Triana, lancar sampai hari H dan sakinah mawaddah warahmah selamanya. 🎊🎊🎊",
		time: "1647310859",
		timeFormatted: "3/15/22 2:20"
	},
	{
		id: "KGtJAVFgVL8JS6ESsvfL",
		name: "Selvi & Suami",
		content: "Barakkallah tri. Smg lancar sehat selamat sampai harinya ya. Aamiin",
		time: "1647257644",
		timeFormatted: "3/14/22 11:34"
	},
	{
		id: "L0WZaqwfwNiFiw508XQp",
		name: "Hapsari",
		content: "Alhamdulillah, selamat ya trin!! Semoga jadi keluarga yg samawa aminn ",
		time: "1647229468",
		timeFormatted: "3/14/22 3:44"
	},
	{
		id: "M5OVpVE1vYVmtRKQqTsY",
		name: "Dery K",
		content: "Selamat mba trin!!! Semoga jadi keluarga bahagia !!",
		time: "1647143358",
		timeFormatted: "3/13/22 3:49"
	},
	{
		id: "NDG0lf9HytmonH1DQFfG",
		name: "Bunga F",
		content: "Bismillah yaaa trinaaa, semoga lancar sampe hari H dan Bahagia dunia akhirat bersama 😊",
		time: "1647140182",
		timeFormatted: "3/13/22 2:56"
	},
	{
		id: "Nuhx1xpLCYEqAdK2isiY",
		name: "Berliana Berliana",
		content: "sing sehat mba trin dan calon.. lancarr sampai hari h 🥰",
		time: "1647138369",
		timeFormatted: "3/13/22 2:26"
	},
	{
		id: "Nz4L1vLAgOedPmQwJNM5",
		name: "Dsw",
		content: "Alhamdulillah selamat singgih & calon istri. Lancar2 dan bahagia selalu Aamiin",
		time: "1647091914",
		timeFormatted: "3/12/22 13:31"
	},
	{
		id: "UmQtsfgpV0wZ1MxNTOkg",
		name: "Ak",
		content: "Dilancarkan acaranya bosku\\nJadi keluarga yang sakinah.\\nMawaddah dan warahmahnya kalian yg akan menjaganya",
		time: "1647016038",
		timeFormatted: "3/11/22 16:27"
	},
	{
		id: "UzOdYeBVkXTNPxlMRwtT",
		name: "Nita Windi",
		content: "Happy wedding kak na! Kembaran dakuuu di shopee sampe mau nikahan cuma beda sehari sama aku. Semoga lancar semuanya ya kak bismillah, samawa kak na & kak singgih 💙🤗",
		time: "1646815269",
		timeFormatted: "3/9/22 8:41"
	},
	{
		id: "WnrsvREGLEMl2A8HpcHc",
		name: "Adek acid",
		content: "Horeee punya om baru 😁",
		time: "1646775518",
		timeFormatted: "3/8/22 21:38"
	},
	{
		id: "XZmIm8pfw4a87ymZWp8x",
		name: "DANIEL",
		content: "LANCAR-LANCAR NGGIH",
		time: "1646643248",
		timeFormatted: "3/7/22 8:54"
	},
	{
		id: "YZRZfLLm1UyzN7hNTMmh",
		name: "ZAHID",
		content: "Selamat buat Singgih dan calon istri, sakinah mawadah warohmah.. aamiin",
		time: "1646642648",
		timeFormatted: "3/7/22 8:44"
	},
	{
		id: "eJtGpkTkT1AbN9RpAY5t",
		name: "Zara audrey",
		content: "Alhamdulilah, selamat menempuh hidup baru buat trina dan singgih. Bahagia terus ya",
		time: "1646568718",
		timeFormatted: "3/6/22 12:11"
	},
	{
		id: "eezw1V1I1FgCxACRy160",
		name: "omdjin",
		content: "Selamat buat Singgih, Trina, & kedua keluarga. Semoga kebahagiaan & keberkahan selalu menyertai kalian berdua. ",
		time: "1646546973",
		timeFormatted: "3/6/22 6:09"
	},
	{
		id: "fAI0R8Rj77EFj7US1EWB",
		name: "Revaa NNH",
		content: "Alhamdulillah Selamaaatt Singgih & Trina, smoga acaranya diberikan keberkahan, kelancaran.. dan smoga bahagia selaluu 😇😇 Aamiin.. ",
		time: "1646546190",
		timeFormatted: "3/6/22 5:56"
	},
	{
		id: "hXoBxke2il9TMJxbXEWg",
		name: "Victor ",
		content: "Alhamdulillah\\nBaarakallahu laka wa baarakaa alaika wa jamaa bainakumaa fii khoir\\nSemoga dilancarkan yak trina dan singgih aamiin\\n\\nSalam arena",
		time: "1646543658",
		timeFormatted: "3/6/22 5:14"
	},
	{
		id: "ijCJ50hyxyT0dFeYfk1P",
		name: "Illona",
		content: "Kotak surat warnanya putih\\nSelamattt buat trina dan Singgih. Semoga samawa hepi terus, turut berbahagia.",
		time: "1646483015",
		timeFormatted: "3/5/22 12:23"
	},
	{
		id: "m8rIJXirDzQe8WYyvLSo",
		name: "Drajat",
		content: "Samawa & lancar terus ya sampe ahir",
		time: "1646471265",
		timeFormatted: "3/5/22 9:07"
	},
	{
		id: "mpONeyqhIgD8n3CS5SJ6",
		name: "Oyand",
		content: "Selamat menempuh hidup baru singgih & calon istri., sakinah mawadah warohmah",
		time: "1646466052",
		timeFormatted: "3/5/22 7:40"
	},
	{
		id: "mvsdgs8de613DX7tLrC7",
		name: "Hendra Firmansyah ",
		content: "Assalamualaikum Warohmatullahi Wabarokatuh \\nSelamat ya Singgih smg bs mjd keluarga sakinah mawardah warohmah. \\nBaarakallaahu laka wa baaraka 'alaika wa jama'a bainakumaa fi khairin",
		time: "1646460576",
		timeFormatted: "3/5/22 6:09"
	},
	{
		id: "oay4VKaEjLMdA9sELuj0",
		name: "Marsudi & endang",
		content: "Turut berbahagia,semoga acaranya lancar,......jdi keluarga yg rukun,langgeng,dan diberkati Tuhan.....",
		time: "1646459378",
		timeFormatted: "3/5/22 5:49"
	},
	{
		id: "q7AstXeK3GK9aW8fPnog",
		name: "Jack d winchester",
		content: "selamat untuk singgih dan trina , semoga selalu bahagia, harmonis . doa terbaik untuk kalian.",
		time: "1646459076",
		timeFormatted: "3/5/22 5:44"
	},
	{
		id: "rb9QWw1RJysHyZqYhim7",
		name: "Tovi",
		content: "Alhamdulillah, Barakallah lakuma...😍maaf belum bisa datang, doa\" baik buat dek singgih sm mbk tri, semoga jd keluarga yg SaMaRa....",
		time: "1646457929",
		timeFormatted: "3/5/22 5:25"
	},
	{
		id: "sAijowSrLGnUvCWii88d",
		name: "Mama Bilal",
		content: "Alhamdulillah selamat trinaaaa! Selamat menempuh hidup baru❤❤❤",
		time: "1646457857",
		timeFormatted: "3/5/22 5:24"
	},
	{
		id: "y3WbLgDwMrh8Ljxrl6BE",
		name: "Zacky Almiqdad Abdul Fatah Setiawan ",
		content: "Harapan dari saya adalah semoga menjadi keluarga yang SAMAWA",
		time: "1646457520",
		timeFormatted: "3/5/22 5:18"
	},
	{
		id: "yPQIluS4ovFykmxsdEOW",
		name: "Risty Hidayanti",
		content: "Mbak Triiiii Alhamdulillah turut senang!!! Akhirnya teman smp-sma-se jakun ku sebentar lagi wisuda dari “hi quality single lady” heuheu.. Semoga lancar persiapannya dan kelak menjadi keluarga SaMaWa hingga maut memisahkan❤️. Salam rindu dari Bojonegoro.",
		time: "1646457367",
		timeFormatted: "3/5/22 5:16"
	},
	{
		id: "zG1jNsxAiNsWPB2htyTV",
		name: "Sup & Van",
		content: "Wah...Selamat Le Singgih & Nduk Triana. Doa kami: Lancar acaranya, tansah binerkahan. Terkabulah doa kalian. ",
		time: "1646457268",
		timeFormatted: "3/5/22 5:14"
	},
	{
		id: "zV784oFWYpIkk2umoSZq",
		name: "Riris",
		content: "Alhamdulillah. Semoga berkah, lancar sampai hari h dan seterusnya :)",
		time: "1646457023",
		timeFormatted: "3/5/22 5:10"
	},
	{
		id: "zjH8LoY0Yv0vMR0Jj3Sx",
		name: "R",
		content: "Selamat menenpuh lembaran baru, semoga menjadi keluarga yg SAMAWA, lekas dikaruniai momongan, bahagian selalu sampai tua",
		time: "1646456920",
		timeFormatted: "3/5/22 5:08"
	}
];