import React, { useState, Fragment, useEffect } from 'react';
import { object } from 'prop-types';
import getQueryValue from '@helpers/getQueryValue';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from 'gatsby';
// firebase
// import {db} from "../../firebase.js"
// import { collection, query as queryFirestore, onSnapshot, doc, getDoc, orderBy }  from "firebase/firestore";
/**
 * List of local components
 */
import Layout from '../components/Layout'
import WelcomeSection from '../components/WelcomeSection'
import QuranSection from '../components/QuranSection'
import CoupleSection from '../components/CoupleSection'
import ScheduleSection from '../components/ScheduleSection'
import ConfirmationSection from '../components/ConfirmationSection'
import GallerySection from '../components/GallerySection'
import WishSection from '../components/WishSection'
import FooterSection from '../components/FooterSection'
import FloatingButton from '../components/FloatingButton'
import Song from '@src/musics/novo-amor.mp3'

import { attendances } from '../components/ConfirmationSection/attendances-data';

// const QuranSection = loadable(() => import('../components/QuranSection'))
// const CoupleSection = loadable(() => import('../components/CoupleSection'))
// const ScheduleSection = loadable(() => import('../components/ScheduleSection'))
// const ConfirmationSection = loadable(() => import('../components/ConfirmationSection'))
// const GallerySection = loadable(() => import('../components/GallerySection'))
// const WishSection = loadable(() => import('../components/WishSection'))
// const FooterSection = loadable(() => import('../components/FooterSection'))
// const FloatingButton = loadable(() => import('../components/FloatingButton'))

const Home = ({ location }) => {
	//localization
  const {t} = useTranslation();

  const [guestName, setGuestName] = useState("")
  const [isInvitation, setIsInvitation] = useState(false)

	const [docData, setDocData] = useState([])

	const id = decodeURIComponent(getQueryValue(location, 'id') || '');

  const fetchAttendances=async()=>{
		if (id) {

			attendances.map((d, index) => {
				if (d.id === id) {
					setGuestName(d.name)
					setIsInvitation(d.isInvitation)
					setDocData(d)
				}
			})

			// const docRef = doc(db, "attendances", id);
			// const docSnap = await getDoc(docRef);
			// if (docSnap.exists()) {
			// 		setGuestName(docSnap.data().name)
			// 		setIsInvitation(docSnap.data().isInvitation)
			// 		const docs = Object.assign({}, {id: id}, docSnap.data())
			// 		setDocData(docs)
			// } else {
			// 	// doc.data() will be undefined in this case
			// 	console.log("No such document!");
			// }
		}
  }

  useEffect(() => {
    fetchAttendances();
  }, [])

	// wishes
  // const [wishes, setWishes] = useState([])

	// useEffect(() => {
	// 	const q = queryFirestore(collection(db, "wish"), orderBy("time", "desc"))
	// 	const unsubscribe = onSnapshot(q, (querySnapshot) => {
	// 		const says = querySnapshot.docs.map((doc) => {
	// 						return { id: doc.id, ...doc.data() }
	// 					})
	// 					setWishes(says)
	// 	});

	// 	return () => unsubscribe()
	// }, []);

  const [showDetailContent, setShowDetailContent] = useState(false);

  const handleClickDetail = () => {
    setShowDetailContent(true);
  };

  const renderDetailContent = () => {
		if (!showDetailContent) {
			return (
				<Fragment>
					<WelcomeSection
					guestName={guestName}
					isInvitation={isInvitation}
					onClickDetail={handleClickDetail}
					/>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<QuranSection />
					<CoupleSection guestName={ guestName ? guestName : 'Guest' }/>
					<ScheduleSection isInvitation={isInvitation} />
					{isInvitation ?
						<ConfirmationSection docData={docData}/>
						: ''
					}
					<GallerySection />
					<WishSection />
					<FooterSection isInvitation={isInvitation}/>
					<FloatingButton isInvitation={isInvitation}/>
				</Fragment>
			);
		}

  };

  return (
    <Layout pageTitle="Home Page">
			<audio id="myAudio" autoPlay loop>
				<source src={Song} type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>
      {renderDetailContent()}
    </Layout>
  )
}

Home.propTypes = {
  location: object.isRequired,
};

export default Home

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;