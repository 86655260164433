import React, { Fragment } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as styleCss from './index.module.css'
import logoTS from '@img/logo.png'
import ImageGallery from 'react-image-gallery';
import Slide from '@stahl.luke/react-reveal/Slide';
import { Box } from '@chakra-ui/react'
import { getSrc } from "gatsby-plugin-image"

const GallerySection = () => {

  const data = useStaticQuery(graphql`
		query {
			allFile(filter: {sourceInstanceName: {eq: "gallery"}}) {
				edges {
					node {
						childImageSharp {
							original: gatsbyImageData(placeholder: BLURRED)
							thumbnail: gatsbyImageData(placeholder: BLURRED, width: 200)
						}
					}
				}
			}
		}
  `)

  // const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  const images = data.allFile.edges.map(({ node }) => {
		const img = {
			original: getSrc(node.childImageSharp.original),
			thumbnail: getSrc(node.childImageSharp.thumbnail)
		}
		return img
	})
	return (
		<Fragment>
			<section className={styleCss.css1w278vc} id='gallery'>
				<Slide bottom>
						<div className={styleCss.cssgmuwbf}>
								<img
										className={styleCss.css1jy55am}
										src={logoTS}
										alt='Logo TS'
								/>
						</div>
				</Slide>
				<Slide bottom>
						<div className={styleCss.csspgctxg}>
								<p>“I have looked at you in million of ways and I have loved you in each.”</p>
								<p className={styleCss.css1c5ij41}>—C.S Khairwar</p>
						</div>
				</Slide>
					<Slide bottom>
						<div className={styleCss.cssgmuwbf}>
							<Box w='100%'>
								<ImageGallery items={images} useBrowserFullscreen={true} showFullscreenButton={false} autoPlay={true} />
							</Box>
						</div>
					</Slide>
			</section>
		</Fragment>
	)
}


export default GallerySection