export const attendances =
[
  {
    id: "cj9QgNlRfuOayuHd8ELb",
    name: "A D Zikri",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "6GrEZPIqk8NvEKA0lreJ",
    name: "Aesha Ariesta",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "ul3UZdZ8PlS5Qzv6URDE",
    name: "Ahmad Ichsan Baihaqi",
    isInvitation: true,
    isAttend: true,
    withPartner: false,
    lang: "en",
    phone: "081310823820"
  },
  {
    id: "iI5V6lMEK1gLVkugiNTW",
    name: "Aji Pujo",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "xz3JIuHTAUOjzu56UMIb",
    name: "Aka",
    isInvitation: false,
    isAttend: null,
    lang: "en",
    phone: null
  },
  {
    id: "41GQnGxbqvQ0q8I6n7hN",
    name: "Akmal Lutfi",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "6a82rUaVZNrfIwS8cK0i",
    name: "Anastasya",
    isInvitation: true,
    isAttend: true,
    withPartner: true,
    lang: "en",
    phone: "08561718194"
  },
  {
    id: "4wKJ9wpJA8mlfzwMf1jm",
    name: "Anda Nur Raditiyo",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "Gtvo003BU3NKlWqNERTs",
    name: "Andi Arif",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "raqwwe4K2cphAnmp3MHI",
    name: "Andri",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "9N17odYeYNkmgAbgcz6V",
    name: "Angga Ariescha",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "grnh9QBDWC0zaJuOIQIc",
    name: "Anjas Randy",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "QZ2uclTcg8VgZE2ccbZa",
    name: "Annisa Dwi Cahya",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "mHH0c4VFp4aGiiPhUKeN",
    name: "Arga Budi P",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "RjNaAuqtFemAaWF7iC3p",
    name: "Arina Devi",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "XQs03k3L6ZGPgYwXAq9D",
    name: "Arnolda Bima",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "qXIZ7pV9fahyYq19pYlx",
    name: "Asep Rosadin",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "AJSOT0JC8QxvS0TpxIRS",
    name: "Asri S",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "Y9eEdVwtltiiKofm6qiS",
    name: "Aulia Bangun",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "2CjP2OFc7La2eIDkiIpf",
    name: "Azhar Yogaswara",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "bnONQdySAg8FxeoTP2zU",
    name: "Baby Amalia",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "1DwGRAArDBHdw916ugMw",
    name: "Beni Saputra",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "loSBjkXBU8I4RBPlBSuO",
    name: "Berliana",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "nzxmjBIwP0w1DYh4oF4L",
    name: "Bernadeth Nababan",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "LwKbbwy7Kszra4DjzDEn",
    name: "Bina Remaja Banjarejo",
    isInvitation: false,
    isAttend: null,
    withPartner: null,
    lang: "id",
    phone: null
  },
  {
    id: "VF5uz9OZnC3RxwNHU6CG",
    name: "Bp. Herbirowo Adjie",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "fxiqosWLJ741ls8BUPJU",
    name: "Bu Ana",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "BleLEcFh8zizWEpJVt9Y",
    name: "Budi Wiryo",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "RKNrLqHtSBsJMVfFzJjz",
    name: "Bunga Fajarani",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "cvs6u72pnYERZNFaSBby",
    name: "Calvin Eldona",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "6leXXyJMckC0VJztyaqO",
    name: "Caraka Yuki Mantowi",
    isInvitation: true,
    isAttend: true,
    withPartner: true,
    lang: "en",
    phone: "081219674001"
  },
  {
    id: "gIjTNwP1yjD4NZuStzBs",
    name: "Chiputra Wahyu A",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "HaP3OrnflzZfytmqxgdB",
    name: "Cila",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "1KU1F8kO4mLBYpcK3VKO",
    name: "Cintya Dewi",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "b9thCDqvDiz7d1N1xxBX",
    name: "Cynthia Fitri Monita",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "Wl1kxKb3euMwSlBJQkOF",
    name: "Dahana Pratanda",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "rE0AyW0SrIu4TRFLRwJi",
    name: "Daniel Ibnu Sina",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "rlsIJXSZ8qbld9bNjiKn",
    name: "Danu Fazri",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "wfYUVwyrdvJ25KDNZdLD",
    name: "Dedeh Anggina",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "fVy8AiX8AHsjaCdbDEvQ",
    name: "Dedi Tri Hermanto",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "zeQr565ILK7X5OOuL8UG",
    name: "Delma Aida Syavitri",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "5NAtCl5Q0wnCNG73O89i",
    name: "Dery Kurniawan",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "ZXMaNb0wRyHU6u9zNVum",
    name: "Desi Purnama Sari",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "kXKrOLP7wQziAPHq4Q6H",
    name: "Deswa Prasetya",
    isInvitation: false,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "2bWu9FjUMIZfLPtCM0Z9",
    name: "Dewi Rista Kurnia",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "EbelvdkU03w5jQsLwWlh",
    name: "Diah Ayu",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "HVhlBWyDcATybQRxBUrd",
    name: "Diah Eka",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "AN6G140SImFJXJSrO5FM",
    name: "Didik Setyo Wibowo",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "OCRHtTqdGLgosTqGtUWh",
    name: "Dienatha Sekar Buana",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "rYxB75ztRn0zTp0OYeMH",
    name: "Dinda Ayu Sekarnurani",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "lMo2c6GrJANLrcNTYcTx",
    name: "Dinda Septyaninditha ",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "QHAerCa56dXUCaFkCaY6",
    name: "Dira & Mario",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "nIN5ZQG1jUwUD1buQcej",
    name: "Dita",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "uABg5KORZA55GzYvfFgi",
    name: "Ditra Novtiansyah",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "ju5VDPN7YBdlyMrqhsCO",
    name: "Dwi Ryananda",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "ltvBzcJlsG5iP2HJgI7u",
    name: "Dwitya Rama",
    isInvitation: false,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "FnpUVx22uzeUDlcURdRF",
    name: "Dyah Mustikaningtyas",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "UNegk0izkS68dkSK7CkT",
    name: "Eka Yanti",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "ujQpi4OF1tJMaT6GUDuB",
    name: "Eliani",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "GXeyoHs6Qu7utZSGiiQ6",
    name: "Emmy Dian",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "uhGbOC23fEACEIILlg6Y",
    name: "Erin Nurcahyawati",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "0vyoCT3Ca6IZJy5m6VsF",
    name: "Eva Purnama Sari",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "RoIkdEmSZTy0tY1jri3U",
    name: "Evi",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "ntcXkyT24r9FMKfalAok",
    name: "Fahmi ",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "QpGHEiisRWopHnQyLnHF",
    name: "Faziral Tri Anugrah",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "WmB7wBjMpJFK5ICs0ZOe",
    name: "FG",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "53CJ4vFcQRXg8hs6XZl0",
    name: "Fildzah Alfita",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "ROLooKN0RPDcu3ssy9uV",
    name: "Fiskal & Luri",
    isInvitation: true,
    isAttend: true,
    withPartner: true,
    lang: "en",
    phone: "082243457667"
  },
  {
    id: "OFAl6Qk3Lvwr8oKbqwAj",
    name: "Fitria Zahrotun Nisa",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "hOg9HzQRxVO35LXLzwV1",
    name: "Gea Wahyu Syahrinnisa",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "wrmqSi0ts3kwxJesMn9d",
    name: "Give Duwi Cahaya",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "fefJ8UErCkhmZqjaNXtz",
    name: "Guntur Wijaya",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "gHaKhmXTuoQWwZZVuICH",
    name: "Hadi Nugroho",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "x0f0y2r7iCtA0YiACtuh",
    name: "Hanna Mutia N",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "jVgkQLC476j5H3tmVgU8",
    name: "Hapsari ",
    isInvitation: true,
    isAttend: true,
    withPartner: false,
    lang: "en",
    phone: "085691661534"
  },
  {
    id: "Dwpu77ghOWvaf1dLznk5",
    name: "Hapsari G",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "d8TAalpK3GE9YeHvCO3U",
    name: "Hendra Firmansyah",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "xgb1rvuoYb2JUFkM7F2b",
    name: "Hendra Firmansyah ",
    isInvitation: true,
    isAttend: false,
    withPartner: false,
    lang: "en",
    phone: "0816784873"
  },
  {
    id: "WD25zJO6srhWQyKisj1a",
    name: "Iha",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "10aYcj1eNMlRx66hQUNY",
    name: "Ika A",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "VScXihgr2Dii50HYEtKf",
    name: "Ika Praesita",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "DczjEBR2HpB3UNAyY5YI",
    name: "Ika Wahyuni",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "MCUnL0EW4jwHWrf1v3I2",
    name: "Imam Nurcahyo",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "4PTiuagUOOkKQle2DvTo",
    name: "Inri Ayu Silvia",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "uyj4UPrV7ozliDe4USH9",
    name: "Intan Nur Pratiwi",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "ns4gnlSkH0ZOT0J8szyY",
    name: "IPA 6",
    isInvitation: false,
    lang: "en"
  },
  {
    id: "1jw1EUdBUyKeF819BdIB",
    name: "Iqbal Firmansyah",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "hkQh9FI5ZyvqvseWuTk6",
    name: "Ira Azmi",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "loCjaef0JrVdhSY2wP9l",
    name: "Irfan Santoso",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "dqpNYJr4wHwefrVTR0pf",
    name: "Irwan Kurniawan",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "7ShdxJ0awyqUER0wcGNs",
    name: "Isnan Khasaeli",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "cRtfq2wpgrajjrVQecxu",
    name: "Jack d winchester",
    isInvitation: true,
    isAttend: false,
    withPartner: false,
    lang: "en",
    phone: "085156556265"
  },
  {
    id: "sGrgTvsQuERvfeF781RY",
    name: "Johanna Illona",
    isInvitation: true,
    isAttend: false,
    withPartner: false,
    lang: "en",
    phone: "089647418052"
  },
  {
    id: "yIDbqL34QKQm0i8aCQfH",
    name: "Ka Ani",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "ZgRKYpbns3lZ7vtcITNw",
    name: "Ka Prima",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "M6vo3Jg0D0Xokz4lTYkE",
    name: "Ka Tari",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "5z4swzIDn5N5Z0uPunHE",
    name: "Kania Bunga Santi",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "nPkCVEnypPsy4e5Rlgzf",
    name: "Kel. H. Abdoel Soekoer",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "JLRpo0XPWNvGOdUMG9uV",
    name: "Kel. Wasamiarja",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "KY6nvpUV7WvNxhxeWHMP",
    name: "Khansa Fimantari",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "x3S1b2DXwsoblC0Uu1Il",
    name: "Kholil Azmi",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "be6uN29URuNfvBJlUE8B",
    name: "Kiki P",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "bDq53lUi8q1a6qpzIlMb",
    name: "Kimia UI 2013",
    isInvitation: false,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "EAxnIu4iTTqQZmNq4COn",
    name: "Kintar Panji Antoro",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "kqoBwJOCGQ7taWrb5mxP",
    name: "Klg. Anom Sentoso",
    isInvitation: false,
    isAttend: null,
    withPartner: null,
    lang: "id",
    phone: null
  },
  {
    id: "FGlaQUSb810e7zNg4gZW",
    name: "Klg. Mbah Ponco",
    isInvitation: false,
    isAttend: null,
    withPartner: null,
    lang: "id",
    phone: null
  },
  {
    id: "VlBq4yOWn6ECgdnWMQEt",
    name: "Kurnia Fauzan",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "aZka1TCMaait2l9jcrpZ",
    name: "Labib Fatkhiyatul",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "JqaY02GrnVoBUaf73RPX",
    name: "Lalu Setiyadi",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "dvhQQ4BCd33d3w8QbD9X",
    name: "Leo Rizky Julian",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "6bXZ3IJMNtb17CFBX8po",
    name: "Lisa Marlina",
    isInvitation: true,
    isAttend: true,
    withPartner: true,
    lang: "en",
    phone: "081290518347"
  },
  {
    id: "EZbWXaYk1XF5tFkzCgZW",
    name: "M. Guntur Nur Hidayah",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "ltGOOaWEYOomD8i2f12R",
    name: "M. Huzni Aziz",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "hfuWbYYCHj10tk1e1dNv",
    name: "M. Nurul Hadi",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "PYZxQedER8NK2mHZhiz9",
    name: "Magdalena Simanjuntak",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "OnuLpNOgedzXDwDrgYDo",
    name: "Mahendra PS",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "pPmkKDSKfWXie7O0TlHL",
    name: "Malik & Wulan",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "H8PhiKIny3AfQhvSpv2P",
    name: "Mas Aldi",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "aC7gN2lO5NnYa0FFoWCm",
    name: "Mas Bobby",
    isInvitation: true,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "deMhWmZqlNAaxaJrdxAr",
    name: "Mas Diki",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "nNdq2ChkZ1KmRHCrmRhn",
    name: "Mas Dwi",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "qZGMNMPUBnUHcoffDGEg",
    name: "Mas Firman",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "e6mqZUTHxEOc5HOnqm7q",
    name: "Mas Iman",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "o7IFarAvvMsfUgvdJrUv",
    name: "Mas Jacky",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "5gyA65rthBTTrlVKRfY5",
    name: "Mba Dipi",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "KBu1lKKvQEkto5iSrU9F",
    name: "Mba Eli",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "PWtgFlqPmQOOcsMWf4Vm",
    name: "Mba Geci",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "6HV5q0jX31ye8W4unAyt",
    name: "Mba Intan",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "MUYu77Mje4YxM475bCjK",
    name: "Mba Pipin",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "tojPs96T9SolXWSPCcfQ",
    name: "Mba Ratih",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "YWxHYSHCfXdTdPJAZd2F",
    name: "Mba Risya",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "0HiPyDpvYpcrbjBgwtig",
    name: "Melinda Alvionita",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "NxxVbDggP4TXR6Ax5bTu",
    name: "Mesa Aqila",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "L0pG7YuYSGGPpwd5oJJU",
    name: "Miftakhul Ali R",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "blFTxAf6ZFWhDjVTxXtV",
    name: "MNC Team",
    isInvitation: false,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "9OiMmxKja5UNfbQQYx4B",
    name: "Muhammad Ibrahim Fadil",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "XDoGx1CWufHW9i7aw01r",
    name: "Mustika Valeria",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "MzGQFpp937m8RvhdBWzT",
    name: "Mutiara Ayu Ramadhani",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "TGGMVUjOvbAVCmBXbJpl",
    name: "Nadia Diandra",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "Vr86LJfBFkNZDE9oPzMl",
    name: "Nadya Farah Kamila",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "GpexhOLyse4jGozji7ng",
    name: "Nita Windi",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "d5oIz88PcGl6ZOrLzx6c",
    name: "Noor Khairunisa",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "EV8fvEpZX0t8szZAqWXl",
    name: "Nur Lathif",
    isInvitation: false,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "VC0q70uO9sMCa9Syuo3h",
    name: "Nurmalia",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "JQ5ByTo2rIqPRpRgD3yj",
    name: "Nursyamsi Olina",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "VU4WkfAX49SkQtfhoqx3",
    name: "Nurun Nisa Maulida",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "7rwFrLTLNqj8Gxe0dQqE",
    name: "NVS",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "1Pl0URvgAiM6lxXc9V2g",
    name: "Oktarina MN",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "Vieh4XPIMl8ExKuImtku",
    name: "Pak Tono",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "jjb2EhbT3uRYrToEacuJ",
    name: "Pradita Eko",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "98dSJtKgqGdywVCmfBob",
    name: "Prida Indah S",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "pHbwNzZI1nHZazOrznt7",
    name: "Puteri Salsabila",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "6mFTOpCnWwPxZ7IT5Y7B",
    name: "Rahma Rafina",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "XXjTjfQbKsWsxKHzzWo1",
    name: "Rahmah Putri",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "43txUNKN5Z6TcPu7Mwyd",
    name: "Raizanes",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "mNqjKvfmPeoADMlc6ybT",
    name: "Raka Alifta",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "D3D4ec9FRx6x7MkKvYsP",
    name: "Ramdha Berlian Syafaat",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "NtDEfXkUAX4bLTmMdnLu",
    name: "Ratu Sartika",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "RhwE15aFAMHM8ooy6b9I",
    name: "Rea Anggraeni",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "Pxj8bnkZeFfPfLLbG6yL",
    name: "Reva Nisatul N",
    isInvitation: false,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "zlxWVOmS8074SHfmf18Q",
    name: "Ricky Firmansyah",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "HUrah5gtXQbhJDRunHn8",
    name: "Riris Azyani Hajar",
    isInvitation: true,
    isAttend: true,
    withPartner: true,
    lang: "en",
    phone: "085956377728"
  },
  {
    id: "A3KbqCT3il82x7Wxbr5V",
    name: "Risty Hidayanti",
    isInvitation: true,
    isAttend: false,
    withPartner: false,
    lang: "en",
    phone: "085258295339"
  },
  {
    id: "bJiA4cWwFEn5pnwk8jwS",
    name: "Rizkyana Novita",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "zYte3yza1zVSMsn0yVTR",
    name: "Rony Eka Putra",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "TL2YefxCHcAiFlt64od2",
    name: "Royandi Azkia",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "op462oxeOikxR3QPrAO0",
    name: "Sandra Chaniago",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "JXpaZfiiBxM0wv9xa5RN",
    name: "Sarah",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "ZaPsL0kWUWNjmk51CZ5f",
    name: "Satryio Tyas Kumolo",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "ggcOMA42J2c6uxbrT2Ui",
    name: "Selvia",
    isInvitation: true,
    isAttend: true,
    withPartner: true,
    lang: "en",
    phone: "081284896558"
  },
  {
    id: "n7h842F43993Iy22h4DK",
    name: "Shabrina Safarah",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "kfHsgD5E9HRSU3E8SkCV",
    name: "Sidiq Purnama",
    isInvitation: false,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "JVebMwvkYUXowtkBa4Mj",
    name: "Sofian Adhe Chandra",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "S47myKsVQGFvTgURqq9A",
    name: "Syifa",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "cP7nkh1S5zkAH42BQogW",
    name: "Tendy Sebastian",
    isInvitation: false,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "DUKDhwOMZUIPX0LknF5E",
    name: "Tendy Triyudi Z",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "WxiUWOXMMqZXrDOsHnR7",
    name: "Tiara Anggrainy",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "LUeRqO3qvmcGM34juJ1F",
    name: "Ua Hamka",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "iPI7jTcJyluC4ItDZwcK",
    name: "Vena Alfiani",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "tC0jiqAOauVS8GS1vaL4",
    name: "Victor Andrianto",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "flrIW3lPPNl0WPv2bzG9",
    name: "Virdan Eka",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "3Mim5OeUyWCjZOw9Hj5F",
    name: "Wayan Susanto",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "4KwB8kMQ0vqB3mxxyulb",
    name: "Widiantari N",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "Y8JTRBxkfQ8B8sGhDhYA",
    name: "Yockie Pratama",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "FX6rDaw58cYUGpF4JBvJ",
    name: "Yoga Pradana",
    isInvitation: false,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "tyfg8wKdVDX9uezaPl8l",
    name: "Yudha Anersha",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "4tDyi1rtmJnqblgV5WHe",
    name: "Yuraeka",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "1m5izYd2lBlIQOUSQ2R5",
    name: "Zahid Ali ",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "r5taf8KqsUXGuKuDsoVJ",
    name: "Zahrotul Syifa",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  },
  {
    id: "5tMhGM2X7fYDUfE7Rs5T",
    name: "Zaky Ramadhan",
    isInvitation: true,
    lang: "en"
  },
  {
    id: "S1felx7UTPitYdrhbvZ6",
    name: "Zara Audrey",
    isInvitation: true,
    isAttend: null,
    withPartner: null,
    lang: "en",
    phone: null
  }
];