import React, { Fragment } from 'react'
import * as styleCss from './index.module.css'
import img1 from '@img/gallery/bg-header3.jpg'
import Slide from '@stahl.luke/react-reveal/Slide';
import {Heading, Text} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image"
import {useI18next} from 'gatsby-plugin-react-i18next';

function FooterSection({ isInvitation}) {
	const {t} = useI18next();
	return (
		<Fragment>
			<div>
				<div className={styleCss.css9nflnj} style={{ paddingBottom: '5px'}}>
						<Slide bottom>
							{isInvitation ?
								<p className={styleCss.cssilpv0t}>
								{t('Thank You')}
								</p>
								:
								<p className={styleCss.cssilpv0t}>
								{t('Due To')}
								</p>
							}
						</Slide>
						<Slide bottom>
								<div className={styleCss.cssgmuwbf}>
									<img className={styleCss.css1ned3wz} src={img1} alt='Foto Footer' />
								</div>
						</Slide>
						<Heading as='h2' fontWeight='light' fontSize='3xl' textAlign='center' color='blackAlpha.800'>With love,</Heading>
						<div className={styleCss.cssgmuwbf} style={{marginTop: '15px'}}>
							<StaticImage
								src='../../images/logo.png'
								alt="Logo TS"
								placeholder="blurred"
								layout="fixed"
								width={50}
							/>
						</div>
				</div>
				<div className={styleCss.cssxh4s46}>
						<div className={styleCss.cssmd9tl3} style={{backgroundColor: 'transparent', transform: 'translateY(-4px)'}}></div>
						<div className={styleCss.css9zjs9g}>
								<Text textAlign='center' color='white'>Created with Love by<br/>Triana &amp; Singgih</Text>
								<div>
										<div className={styleCss.cssgmuwbf}><hr className={styleCss.css2prcv0} /></div>
										<div className={styleCss.cssgmuwbf}><p className={styleCss.css5qjjla}>© 2022 Triana &amp; Singgih. All Rights Reserved</p></div>
										<div className={styleCss.cssgmuwbf}><a target="_blank" rel="noopener noreferrer" className={styleCss.css11atzhw} href="https://open.spotify.com/track/19jTCAxeVnkwKnWHSsdg0i?si=DgTeG03FSEa-g7H1GTTRZg&utm_source=copy-link">Song by Novo Amor &amp; Gia Margaret – Lucky For You</a></div>
								</div>
						</div>
				</div>
			</div>
		</Fragment>
	)
}

export default FooterSection