import React, { Fragment } from 'react'
import * as styleCss from './index.module.css'
import AttendanceForm from './AttendaceForm'
import Slide from '@stahl.luke/react-reveal/Slide';
import {useI18next} from 'gatsby-plugin-react-i18next';
import { Text, Box, UnorderedList, ListItem } from '@chakra-ui/react'

function ConfirmationSection({ docData }) {
	const {t} = useI18next();
	return(
		<Fragment>
		<div className={styleCss.css6rxair} id='covid'>
				<div className={styleCss.css1gnsnpc}></div>
				<div className={styleCss.csslp4qud}>
						<div className={styleCss.cssdt5sgu}>
							<Slide bottom>
								<section>
										<div className={styleCss.cssg50whj}>
											<div style={{position: 'relative', overflow: 'hidden', backgroundColor: 'transparent'}}>
												<div aria-hidden="true" style={{width: '100%', paddingBottom: '100%'}}></div>
												<Box
													style={{
														position: 'absolute',
														top: '0px',
														left: '0px',
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														objectPosition: 'center center',
														opacity: '1',
														transition: 'opacity 500ms ease 0s',
														borderRadius: '16px',
														backgroundColor: 'white',
														color: 'black'
													}}
												>
													<Text className={styleCss.txtCovid} align='center'>
														{t('Covid Protocol')}
													</Text>
													<UnorderedList className={styleCss.unorderList}>
														<ListItem>
															<Text fontSize={{ base: '10px', md: 'sm', lg: 'sm' }}>{t('Covid-1')}</Text>
														</ListItem>
														<ListItem>
															<Text fontSize={{ base: '10px', md: 'sm', lg: 'sm' }}>{t('Covid-2')}</Text>
														</ListItem>
														<ListItem>
															<Text fontSize={{ base: '10px', md: 'sm', lg: 'sm' }}>{t('Covid-3')}</Text>
														</ListItem>
														<ListItem>
															<Text fontSize={{ base: '10px', md: 'sm', lg: 'sm' }}>{t('Covid-4')}</Text>
														</ListItem>
														<ListItem>
															<Text fontSize={{ base: '10px', md: 'sm', lg: 'sm' }}>{t('Covid-5')}</Text>
														</ListItem>
														<ListItem>
															<Text fontSize={{ base: '10px', md: 'sm', lg: 'sm' }}>{t('Covid-6')}</Text>
														</ListItem>
													</UnorderedList>
												</Box>
											</div>
										</div>
								</section>
							</Slide>
								<section className={styleCss.css1e4qk1x} id='rsvp'>
										<div className={styleCss.cssokdg48}>
												<Slide bottom>
														<p className={styleCss.css1npegvt}>{t('Your presence')}</p>
												</Slide>
												<Slide bottom>
													<AttendanceForm docData={docData}/>
												</Slide>
										</div>
								</section>
						</div>
				</div>
				<div className={styleCss.css1200jbv}></div>
			</div>
		</Fragment>
	)
}

export default ConfirmationSection