import React from 'react';
import { graphql, useStaticQuery} from 'gatsby';
import { Helmet } from 'react-helmet';

export default function SEO({ title, description, image }) {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
          title
          description
          image
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  const seo = {
    title: title || defaults.title,
    description: description || defaults.description,
    image: `${defaults.siteUrl}${image || defaults.image}`,
  };

  return (
    <Helmet title={seo.title}>
      <html lang="en" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

			<meta property="og:title" content="The Wedding of Triana & Singgih" />

			<meta property="og:image" content={seo.image} />
			<meta property="og:image:secure_url" content={seo.image} />
			<meta property="og:image:type" content="image/jpeg" />
			<meta property="og:image:width" content="300" />
			<meta property="og:image:height" content="300" />
			<meta property="og:image:alt" content="The Wedding of Triana & Singgih" />

			<meta property="og:url" content="https://triana.singgih.me" />
			<meta property="og:site_name" content="The Wedding of Triana & Singgih" />
			<meta property="og:description" content={seo.description} />
			<meta property="og:type" content="website" />

			<meta name="twitter:title" content="The Wedding of Triana & Singgih" />
			<meta name="twitter:description" content={seo.description} />
			<meta name="twitter:image" content={seo.image} />
			<meta name="twitter:url" content="https://triana.singgih.me" />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:creator" content="@singgihadn" />
    </Helmet>
  );
}
