import React, { Fragment } from 'react';
import * as styleCss from './index.module.css'
import { IconButton, Link, Heading } from '@chakra-ui/react'
import { FaInstagram } from "react-icons/fa";
import Slide from '@stahl.luke/react-reveal/Slide';
import { StaticImage } from "gatsby-plugin-image"
import {useI18next} from 'gatsby-plugin-react-i18next';

function CoupleSection({ guestName }) {
	const {t} = useI18next();
	return (
		<Fragment>
				<section id="couple" className={styleCss.css5ntc9t}>
					<Slide bottom>
							<p className={styleCss.cssxi606m}>
							{t('Dear')} {guestName},</p>
					</Slide>
					<Slide bottom>
							<p className={styleCss.css1skkkkh}>{t('By the blessing')}</p>
					</Slide>
					<div className={styleCss.cssold1by}>
							<div className={styleCss.css1wcaknn}>
									<div className={styleCss.cssgmuwbf}>
											<Slide bottom>
													<Heading as='h2' fontWeight='light' fontSize='5xl' mb='-10px' color='mainColorText' fontFamily='couple'>Triana</Heading>
											</Slide>
									</div>
									<div className={styleCss.css1co45sp}>
											<Slide bottom>
													<p className={styleCss.css1ozmglt}>Rizki Triana Sari</p>
											</Slide>
											<Slide bottom>
													<p className={styleCss.css1wbejut}>
															<span>
																	{t('Daughter of')} Mustafa, <br />
																	{t('And Mrs')} Nurul Hidayah
															</span>
													</p>
											</Slide>
											<div className={styleCss.css4bbl5h}>
											<Slide bottom>
														<Link href='https://instagram.com/trianarizki' isExternal>
															<IconButton
																icon={<FaInstagram/>}
																size='sm'
																color='white'
																backgroundColor='#e3a74b'
																isRound={true}
															/>
														</Link>
													</Slide>
											</div>
									</div>
									<Slide bottom>
											<div className={styleCss.cssgmuwbf}>
												<StaticImage src="../../images/girl.png" alt="Triana"
      										placeholder="blurred"
													layout="constrained"
													style={{ margin: '8px 0px' }}
												/>
											</div>
											</Slide>
							</div>
					</div>
					<div className={styleCss.cssold1by}>
							<div className={styleCss.css1wcaknn}>
									<div className={styleCss.cssgmuwbf}>
										<Slide bottom>
											<Heading as='h2' fontWeight='light' fontSize='5xl' mb='-10px' color='mainColorText' fontFamily='couple'>Singgih</Heading>
											</Slide>
									</div>
									<div className={styleCss.css1co45sp}>
									<Slide bottom>
													<p className={styleCss.css1ozmglt}>Singgih Adi Nugroho</p>
											</Slide>
											<Slide bottom>
													<p className={styleCss.css1wbejut}>
															<span>
																	{t('Son of')} Sukamto (Alm.), <br />
																	{t('And Mrs')} Winarsih
															</span>
													</p>
											</Slide>
											<div className={styleCss.css4bbl5h}>
												<Slide bottom>
														<Link href='https://instagram.com/singgihadn' isExternal>
															<IconButton
																icon={<FaInstagram/>}
																size='sm'
																color='white'
																backgroundColor='#e3a74b'
																isRound={true}
															/>
														</Link>
													</Slide>
											</div>
									</div>
									<Slide bottom>
											<div className={styleCss.cssgmuwbf}>
												<StaticImage src="../../images/man.png" alt="Singgih"
													placeholder="blurred"
													layout="constrained"
													style={{ margin: '8px 0px' }}
												/>
											</div>
									</Slide>
							</div>
					</div>
				</section>
		</Fragment>
	)
}

export default CoupleSection;