import React, { Fragment } from 'react'
import * as styleCss from './index.module.css'
import {
	Flex,
	Box,
	Text,
	Avatar,
	WrapItem
} from '@chakra-ui/react'

function Wish({ wish }) {
	return(
		<Fragment>
			<Flex className={styleCss.css1jkh2kp}>
					<Box className={styleCss.css1ay9vb9}>
						<WrapItem>
							<Avatar showBorder size="md" name={wish.name} />
						</WrapItem>
					</Box>
					<Box className={styleCss.css1x97c6v}>
							<div className={styleCss.cssrcmbwp}>
									<div className={styleCss.css70qvj9}>
											<Text className={styleCss.cssone7ov}>{wish.name}</Text>
									</div>
									<Text className={styleCss.cssitvw0n}>
											{wish.content}
									</Text>
							</div>
					</Box>
			</Flex>
		</Fragment>
	)
}

export default Wish