import React, { Fragment } from 'react'
import * as styleCss from './index.module.css'
import WishForm from './WishForm'
import Wish from './Wish'
import {Box, Heading} from '@chakra-ui/react'
import Slide from '@stahl.luke/react-reveal/Slide';
import bgHeader from '@img/bg-header.jpeg'
import {useI18next} from 'gatsby-plugin-react-i18next';
import { wishes } from './wishlist-data';

const WishSection = () => {
	const {t} = useI18next();
	return (
		<Fragment>
			<section style={{ background: `url(${bgHeader}) center center / cover` }} id='wishes'>
				<div className={styleCss.cssl2ddf7}>
						<div className={styleCss.css19midj6}>
								<Slide bottom>
									<Heading as='h2' fontWeight='light' fontSize='4xl' textAlign='center' mt='32px' color='white'>{t('Send Wishes')}</Heading>
										</Slide>
										<Slide bottom>
												<div className={styleCss.css5ks52y}><p className={styleCss.css1wocpjt}>{t('Your Wishes')}</p></div>
										</Slide>
								<WishForm></WishForm>
								<Slide bottom>
										<Box className={styleCss.cssxe0nh5}>
											{wishes.map((wish, index) => <Wish key={index} wish={wish}></Wish>)}
										</Box>
								</Slide>
						</div>
						<div className={styleCss.cssh1c448} style={{backgroundColor: 'transparent'}}></div>
				</div>
			</section>
		</Fragment>
	)
}

export default WishSection