import React, { useState } from 'react';
import { string, bool, func } from 'prop-types';
import {
	css1jdnwu4, cssfevx71, css13o7eu2, cssgmuwbf, css3t04x3, cssv8lbct, css1lrmxgu, css9h0ggy, css18r7j5z, homeTitle3, logoTitle
} from './index.module.css'
import ScrollToDown from './ScrollToDown'
import Slide from '@stahl.luke/react-reveal/Slide';
import { Heading } from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image"

const DELAY_TIME = 1500;

const WelcomeSection = ({ guestName, isInvitation, onClickDetail }) => {
  const [loading, setLoading] = useState(false);
  const [alreadyDownloadData, setAlreadyDownloadData] = useState(false);

  const handleScrollTo = () => {
    /** scroll into detail view */
    const element = document.getElementById('start-scroll')
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  }

  const toggleMusic = () => {
    const myAudio = document.getElementById('myAudio');
		myAudio.play()
  };

	const handleButtonClick = async (e) => {
    e.preventDefault(); //prevent transition
    await handleShowDetail(); // wait until it finishes
		toggleMusic();
	}

  const handleShowDetail = () => {
    if (loading) return undefined;

    if (!alreadyDownloadData) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        setAlreadyDownloadData(true);
				onClickDetail();
        handleScrollTo();
      }, DELAY_TIME);
    } else {
      handleScrollTo();
    }
  };

	return (
		<div className={css1jdnwu4}>
			<div className={cssfevx71}>
					<div className={css13o7eu2}>
						<Slide bottom>
							<div style={{paddingTop: "10rem"}}>
									<div className={logoTitle}>
										<StaticImage
											src='../../images/logo.png'
											alt="Logo TS"
											placeholder="blurred"
											layout="fixed"
											style={{ margin: '8px 0px' }}
											width={80}
										/>
									</div>
									<div>
										<Heading as='h2' size='2xl' color='mainColorText' py={4} fontWeight='medium' textAlign='center' fontFamily='couple'>Triana &amp; Singgih</Heading>
									</div>
									<div>
										<h2 className={homeTitle3}>20.03.2022</h2>
									</div>
							</div>
						</Slide>
						<div>
							<ScrollToDown loading={loading} onClick={(e) => handleButtonClick(e)} />
						</div>
					</div>
			</div>
			<div className={css3t04x3}>
					<div className={cssv8lbct}></div>
					<div className={css1lrmxgu}>
						<div className={cssgmuwbf}>
								<p className={css9h0ggy}>© 2022 Triana &amp; Singgih. All Rights Reserved</p>
						</div>
						<div className={css18r7j5z}></div>
					</div>
			</div>
		</div>
	)
}

WelcomeSection.propTypes = {
  guestName: string.isRequired,
  isInvitation: bool.isRequired,
  onClickDetail: func.isRequired,
};

WelcomeSection.defaultProps = {
  codeLink: '',
};

export default WelcomeSection